.messages {
	width: 100%;
	text-align: center;

	&__text {
		padding: 0.6em 1em;
		font-weight: bold;
		color: white;
		background-color: var(--t-accent-color-dark);
		display: inline-block;
		border-bottom-left-radius: var(--component-border-rad);
		border-bottom-right-radius: var(--component-border-rad);
	}
}
