@use "constants";
@use "mixins";

.pagination {
	display: flex;
	justify-content: space-between;
	gap: 23px;

	&__button-group {
		display: flex;
		align-items: center;
		gap: 5px;
	}

	&__button {
		display: flex;
		justify-content: center;
		align-items: center;
		border: 2px solid var(--t-accent-color);
		background: var(--t-accent-color);
		color: var(--t-page-bg-color);
		border-radius: 100%;
		width: 30px;
		height: 30px;

		&.active {
			background: var(--t-page-bg-color);
			color: var(--t-accent-color);
		}
	}

	&__next-button {
		flex: 1;
	}
}

@include mixins.from-md {
	.pagination {
		gap: 150px;

		&__button-group {
			gap: 10px;
		}

		&__button {
			width: 50px;
			height: 50px;
		}
	}
}

@include mixins.to-md {
	.pagination__next-button {
		padding-top: 4px;
		padding-bottom: 4px;
	}
}
