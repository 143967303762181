@use "mixins";
@use "global/bases";

.l-navigation {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	&__item {
		display: block;
		line-height: 1;
		position: relative;
		margin: 0;

		&-link {
			width: auto;
		}
	}
	&__item + &__item {
		margin-top: 10px;
	}

	&__actions {
		&-link {
			display: flex;
			align-items: center;
			cursor: pointer;
		}
	}
}

@include mixins.to-lg {
	.l-navigation {
		&__actions {
			margin-top: var(--p-rhythm);
			&-link {
				margin-bottom: var(--p-rhythm);
			}
		}

		&__cart {
			@include bases.base-btn;
			z-index: 6;
			height: 30px;
			margin-bottom: 0 !important;
			bottom: 20px;
			position: absolute;
			right: var(--p-rhythm);
			font-size: var(--fs-prose-body);
			right: 150px;

			& > span {
				margin-left: 0.5em;
			}
		}
	}
}

@include mixins.to-md {
	.l-navigation {
		&__cart {
			right: 115px;
		}
	}
}

@include mixins.from-lg {
	.l-navigation {
		flex: 1;
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;

		&__item {
			&-link {
				@include bases.base-btn;
				height: 50px;
				border-radius: 25px;
			}
		}

		&__item + &__item {
			margin-top: 0;
			margin-left: 25px;
		}

		&__actions {
			display: flex;
			position: absolute;
			bottom: calc(100% + 20px);
			right: 0;
			&-link {
				font-size: 18px;
				margin-left: 20px;
				font-weight: 600;
				&:hover {
					text-decoration: underline;
				}
			}
		}

		&__cart {
			&-count {
				font-size: inherit;
				border: 2px solid white;
				padding: 1px 12px;
				border-radius: 25px;
				margin-left: 8px;
				line-height: 1;
			}
			text-decoration: none !important;
		}
	}
}
