@use "mixins";

.content-picker {
	max-width: 100%;
	color: var(--t-accent-color);
	font-weight: 600;
	display: flex;
	justify-content: space-between;
	&__fieldset {
		padding: 0;
		border: none;
		legend {
			float: left;
		}
	}
	input {
		display: none;

		&:checked {
			+ label {
				text-decoration: underline;
				text-decoration-thickness: 2px;
				text-underline-offset: 5px;
			}
		}

		&:not(:checked) {
			+ label {
				cursor: pointer;
			}
		}
	}

	label {
		display: inline;
		margin-left: 0.9em;
	}

	&__control-all {
		width: auto;
		margin-left: auto;
		& > svg {
			transition: transform 150ms ease-in-out;
			will-change: transform;
			width: 18px;
			height: 18px;
			margin-bottom: -4px;
			margin-left: 5px;
		}

		&.close {
			& > svg {
				transform: rotate(180deg);
			}
		}
	}

	@include mixins.to-sm {
		align-items: flex-end;
		margin-bottom: 20px;
		fieldset {
			display: grid;
			grid-template-columns: auto minmax(0, 1fr);

			& > label {
				grid-column: 2;
			}
		}
	}
}
