/**
 * Media Queries - *Try* and make everything fit within these - use @from/to/between
 */
$bp-tiny: 320px;
$bp-small: 768px;
$bp-medium: 1024px;
$bp-large: 1280px;
$bp-very-large: 1920px;

/**
  * Font definitions
  */
$body-font: "omnes-pro", sans-serif;
$heading-font: $body-font;
$code-font: monaco, "anonymous-pro", monospace;
$font-y-offset: -0.2em;

/**
  * Transitions
  */
$base-anim-rhythm: 300ms;
$transition-default: all $base-anim-rhythm ease-in-out;
