@use "components/buttons";
@use "mixins";

shop-cart {
	$width: 500px;
	position: fixed;
	background: rgba(0, 0, 0, 0.5);
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	opacity: 0;
	z-index: -10;
	transition: all 200ms ease-in-out;

	&::part(container) {
		display: flex;
		flex-direction: column;
		background: white;
		position: absolute;
		width: $width;
		height: calc(100% - (2 * var(--p-rhythm-lg)));
		top: 0;
		right: 0;
		opacity: 1;
		transform: translateX(#{$width + 80px});
		padding: var(--p-rhythm-lg) var(--p-rhythm);
		transition: transform 300ms ease-in-out;
		transition-delay: 0ms;
	}

	&::part(heading) {
		font-size: var(--fs-prose-title);
		font-weight: 600;
		margin: 0;
		margin-bottom: var(--p-rhythm-sm);
	}

	&::part(items) {
		padding: 1em 0;
		border-top: 2px solid var(--t-accent-color);
		border-bottom: 2px solid var(--t-accent-color);
		display: grid;
		gap: 20px;
	}

	&::part(total) {
		font-weight: 600;
		margin-top: auto;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	&::part(store-button),
	&::part(checkout) {
		@include buttons.content-button;
		text-decoration: none;
		margin-top: 30px;
		font-size: var(--fs-ui-body);
		border-radius: 1.2em;
	}

	&::part(checkout) {
		flex-basis: 100%;
	}

	&[open] {
		&::part(container) {
			transform: translateX(0);
			transition-delay: 200ms;
		}
	}

	@include mixins.from-md {
		&::part(container) {
			height: calc(100% - (2 * var(--p-rhythm)));
			padding: var(--p-rhythm) var(--p-rhythm-sm);
		}
	}

	@include mixins.to($width) {
		&::part(container) {
			width: 80%;
		}
	}
}
