@use "constants";
@use "mixins";

@mixin heading-font() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: constants.$heading-font;
	font-weight: 600;
}

@mixin body-font() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: constants.$body-font;
}

@mixin base-heading() {
	@include heading-font;
	line-height: 1.1;
	margin: 0;
	letter-spacing: 0;
}

@mixin base-link() {
	transition: border-color 150ms ease-in;
	color: inherit;
	display: inline-block;
	position: relative;

	@supports (text-underline-offset: 4px) {
		text-decoration: underline;
		text-decoration-thickness: 1px;
		text-underline-offset: 4px;
		text-decoration-skip-ink: auto;
		&:hover,
		&:focus,
		&:active {
			text-decoration-color: var(--t-accent-color);
		}
	}
	@supports not (text-underline-offset: 3px) {
		border-bottom: 1px solid var(--t-text-color);
		padding-bottom: 0px;
		&:hover,
		&:focus,
		&:active {
			border-bottom-color: var(--t-accent-color);
		}
	}
}

@mixin base-bg-gfx {
	background: url(../images/bg.svg);
	background-size: 100% auto;
	background-position: center -70px;
	background-attachment: fixed;
	background-color: var(--t-accent-color);
}

@mixin base-btn() {
	transition: all 250ms ease-in-out;
	height: 40px;
	border-radius: 20px;
	font-weight: 600;
	background: white;
	border: 2px solid white;
	color: var(--t-accent-color);
	padding: 0 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: auto;

	&:hover,
	&:focus,
	&:active {
		color: white;
		background: var(--t-accent-color);
	}
}
