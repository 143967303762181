@use "mixins";

@include mixins.from-lg {
	.l-site-header,
	.l-page,
	.l-site-footer {
		.is-home & {
			transition: opacity 500ms ease-in-out;
			transition-delay: 500ms;
			opacity: 0;
		}
		.loaded & {
			opacity: 1;
		}
	}

	.l-site-header {
		.l-site-header__logo,
		.l-navigation {
			.is-home & {
				transition: opacity 500ms ease-in-out;
				transition-delay: 1000ms;
				opacity: 0;
			}
			.loaded & {
				opacity: 1;
			}
		}
	}

	.l-home-fold {
		&__tagline {
			.is-home & {
				transition: opacity 500ms ease-in-out;
				transition-delay: 1500ms;
				opacity: 0;
			}
			.loaded .is-visible & {
				opacity: 1;
			}
		}
		&__banner-image {
			.is-home & {
				transition:
					opacity 500ms ease-in-out,
					transform 500ms ease-in-out;
				transition-delay: 1500ms;
				opacity: 0;
				transform: translateY(50px);
			}
			.loaded .is-visible & {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}

	.l-page__section {
		.content-cta,
		.card-group .card {
			transition:
				opacity 500ms ease-in-out,
				transform 500ms ease-in-out;
			opacity: 0;
			transform: translateY(50px);
			&:nth-child(2) {
				transition-delay: 250ms;
			}
			&:nth-child(3) {
				transition-delay: 500ms;
			}
		}

		&.is-visible {
			.content-cta,
			.card-group .card {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}
}
