@use "mixins";

.form-field {
	position: relative;
	width: 100%;

	&__field,
	&__label {
		display: block;
		line-height: 1.3;
		background: none;
	}

	&__field {
		border: 0 none;
		padding: 12px;
		width: 100%;
		appearance: none;
		border-radius: 15px;
		border: 2px solid var(--t-accent-color);
		&:focus {
			outline: none !important;
		}
	}

	&__boolean-group {
		> * + * {
			margin-top: calc(var(--p-rhythm-sm) / 2);
		}
	}

	&__label {
		font-size: var(--fs-prose-body);
		margin-bottom: 5px;
		&-required {
			color: red;
		}
	}

	&__error-messages {
		color: red;
		width: 100%;
		&-single {
			margin-top: 10px;
		}
	}

	&__help {
		width: 100%;
	}
}

@include mixins.from-md {
	.form-field {
		font-size: 12px;
		&__field {
			padding: 20px;
		}
		&__label {
			margin-bottom: 12px;
		}
	}
}
