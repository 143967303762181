@use "mixins";
@use "global/bases";

.l-site-footer {
	@include bases.base-bg-gfx;
	position: relative;
	max-width: var(--page-width);
	margin: var(--p-rhythm) auto 0 auto;
	padding: var(--p-rhythm);
	padding-bottom: 30px;
	border-radius: var(--block-border-rad) var(--block-border-rad) 0 0;

	color: white;

	&__logo {
		display: block;
		height: 59px;
		img {
			width: auto;
			height: 100%;
		}
	}
	&__contact-title {
		@include bases.base-heading;
		font-size: var(--fs-cta-text-title);
		margin: var(--p-rhythm-lg) 0 var(--p-rhythm) 0;
	}
	.l-navigation {
		margin: var(--p-rhythm-lg) 0;
	}
	&__bottom-links {
		width: auto;
		margin-top: var(--p-rhythm-lg);
		display: inline-flex;
		align-items: center;
		gap: 1.2rem;

		& > a {
			font-size: 16px;
			white-space: nowrap;
			width: auto;
		}
	}

	&__copyright {
		font-size: 13px;
		margin-top: 1rem;
	}
}

@include mixins.from-md {
	.l-site-footer {
		position: relative;
		display: flex;

		display: grid;
		grid-template-areas:
			"text logo"
			"contact navigation"
			"privacy social"
			"copyright copyright";

		padding: var(--p-rhythm);
		padding-bottom: 1rem;
		&__logo {
			grid-area: logo;
			& > img {
				width: 157px;
				height: auto;
				margin-left: auto;
			}
		}
		&__contact-title {
			grid-area: text;
			margin: 0;
			width: 100%;
			flex: 1;
			flex-basis: 100%;
		}
		&__contact-link {
			flex: 1;
			margin-right: var(--p-rhythm);
			grid-area: contact;
			align-self: flex-start;
			max-width: 300px;
		}
		.l-navigation {
			grid-area: navigation;
			flex-direction: row;
			justify-content: flex-end;
			align-items: flex-end;
			margin: 0;
			&__item {
				margin-left: 25px;
			}
			&__item-link {
				white-space: nowrap;
				margin-top: 0 im !important;
			}
		}
		&__bottom-links {
			grid-area: privacy;
			margin: 0;
			align-self: flex-end;
		}
		.social-bar {
			grid-area: social;
			flex: 1;
			justify-content: flex-end;
			order: 100; // Move to the end
		}

		.l-navigation,
		&__contact-link {
			margin-top: var(--p-rhythm);
			margin-bottom: var(--p-rhythm-sm);
		}

		&__copyright {
			grid-area: copyright;
		}
	}
}
