@use "constants";
@use "mixins";
@use "global/bases";

.l-home-fold {
	padding: var(--p-rhythm-lg) var(--p-rhythm) 0 var(--p-rhythm);
	overflow: hidden;
	&__tagline {
		@include bases.base-heading;
		color: white;
		font-size: var(--fs-home-fold-title);
		margin-bottom: 1em;
	}
	&__banner-image {
		width: 100%;
		height: auto;
		border-radius: var(--component-border-rad);
		overflow: hidden;
		display: block;
	}
}

@include mixins.from-md {
	.l-home-fold {
		padding-top: 0;
		&__tagline {
			margin: calc(var(--p-rhythm) * 0.3) 0 calc(var(--p-rhythm) * 1.5) 0;
		}
	}
}
