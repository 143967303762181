@use "mixins";

.article-details {
	&__title {
		font-size: var(--fs-page-title);
		color: var(--t-accent-color);
		margin-bottom: 0;
	}

	&__date {
		color: var(--t-text-color-light);
		margin-top: 0.4em;
	}
}

@include mixins.from-md {
	.article-details {
		&__title {
			margin-bottom: 0;
		}

		&__date {
			margin-bottom: 1em;
		}
	}
}

.l-prose-blocks .article-details {
	max-width: none;
}
