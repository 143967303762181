@use "mixins";

.forwardlink,
.backlink {
	display: flex;
	align-items: center;
	color: var(--t-accent-color);
	&__icon {
		position: relative;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background: var(--t-accent-color-dark);

		svg {
			width: auto;
			height: 12px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

.backlink {
	&__icon {
		margin-right: 20px;
	}
}

.forwardlink {
	&__icon {
		margin-left: 20px;
		transform: rotate(180deg);
	}
}

@include mixins.from-md {
	.forwardlink,
	.backlink {
		&__icon {
			width: 50px;
			height: 50px;
			svg {
				height: 16px;
			}
		}
	}
}
