@use "mixins";
@use "global/bases";

.l-form {
	.form-field + .form-field,
	.form-field + .btn {
		margin-top: calc(var(--p-rhythm-sm) * 2);
	}

	&__reset-password {
		@include bases.base-link;
		width: auto;
		margin-top: 1em;
	}
}

@include mixins.to-md {
	.l-form {
		.btn {
			width: 100%;
		}
	}
}

@include mixins.from-md {
	.l-form {
		.form-field + .form-field,
		.form-field + .btn {
			margin-top: var(--p-rhythm-sm);
		}
	}
}
