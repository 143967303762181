@use "mixins";

.l-prose-blocks {
	&__title {
		font-size: var(--fs-page-title);
		color: var(--t-accent-color);
		margin-bottom: 1em;

		&--compact {
			margin-bottom: 0.2em;
		}
	}

	&__subtitle {
		font-size: var(--fs-prose-title);
		color: var(--t-text-color);
	}

	> * + * {
		&:not(.forwardlink):not(.pagination) {
			margin-top: var(--p-rhythm-lg);
		}
	}

	> .rich-text {
		line-height: 1.25;
		p:first-child {
			font-weight: 600;
			font-size: var(--fs-prose-title);
			margin-bottom: 1.5em;
		}
	}

	&__subtitle + .btn-group {
		margin-top: calc(var(--p-rhythm-sm) * 2);
	}
	.btn-group + &__subtitle {
		margin-top: calc(var(--p-rhythm) * 2);
	}

	video {
		width: 100%;
	}
}

@include mixins.from-md {
	.l-prose-blocks {
		> * {
			max-width: var(--text-column-width);
		}

		> * + * {
			&:not(.forwardlink):not(.pagination) {
				margin-top: var(--p-rhythm);
			}
		}

		.content-picker,
		.accordion,
		&__title {
			max-width: var(--page-column-width);
		}

		.l-form,
		.resource-group,
		.article-group {
			max-width: 815px;
		}

		.l-curriculum-block {
			max-width: none;
		}

		.captioned-media {
			max-width: none;
			width: calc(100% + var(--p-rhythm));
			margin-right: calc(-1 * var(--p-rhythm));

			&--text-width {
				max-width: var(--text-column-width);
				display: block;
			}
		}

		.accordion {
			.captioned-media {
				width: 139%;
				grid-template-columns: 72% 1fr;
				margin-right: 0;
				&__caption {
					padding-top: 0;
				}
			}
		}

		&__subtitle + .btn-group {
			margin-top: calc(var(--p-rhythm-sm) / 2);
		}
		.btn-group + &__subtitle {
			margin-top: var(--p-rhythm);
		}
	}
}
