@use "mixins";

.html-block {
	&__aspect {
		@include mixins.aspect-ratio(16, 9);
		& > iframe {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
	}
}
