.blockquote {
	padding: var(--p-rhythm-sm) 0;
	border-top: 2px solid var(--t-accent-color);
	border-bottom: 2px solid var(--t-accent-color);
	margin: 0;
	color: var(--t-accent-color);
	&__body {
		font-weight: bold;
		font-style: italic;
		* {
			line-height: 1.25;
			font-size: var(--fs-prose-title);
		}
	}
	&__citation {
		display: flex;
		align-items: center;
		font-style: normal;
		margin-top: calc(var(--p-rhythm-sm) * 0.8);
		&:before {
			content: "";
			width: 90px;
			border-bottom: 1px solid var(--t-accent-color);
			margin-right: 20px;
		}
	}
}
