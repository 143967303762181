@use "mixins";

.captioned-media {
	margin: 0;
	&__asset {
		width: 100%;
		height: auto;
		display: block;
		border-radius: var(--component-border-rad);
	}
	&__caption {
		font-size: 0.8em;
		padding: 30px 0 0 0;
	}
}

@include mixins.from-md {
	.captioned-media {
		display: grid;
		gap: var(--p-rhythm-sm);
		grid-template-columns: var(--page-column-width) 1fr;

		&__caption {
			align-self: end;
			border-bottom: 2px solid var(--t-accent-color);
			padding: 30px 0;
		}
	}
}
