@use "mixins";

.accordion {
	margin-top: var(--p-rhythm-sm) !important;
	border-bottom: 2px solid var(--t-accent-color);
	&--item {
		$i: &;
		border-top: 2px solid var(--t-accent-color);
		padding: var(--p-rhythm) 0;

		&.is-active {
			#{$i}-trigger {
				svg {
					transform: rotate(180deg);
				}
			}
		}

		&-supertitle {
			display: flex;
			margin-bottom: 0.3em;
			align-items: center;
			font-weight: 400;
		}

		&-category {
			margin-right: 1em;
			text-transform: uppercase;
			color: var(--t-text-color-light);
		}

		&-type {
			font-size: 16px;
			border-radius: 19px;
			padding: 0.3em 1.1em;

			&__teacher {
				background-color: #fdb7ec;
			}

			&__student {
				background-color: #d7e4f4;
			}
		}
		&-trigger {
			display: block;
			appearance: none;
			outline: none !important;
			position: relative;
			font-size: var(--fs-ui-title);
			font-weight: 600;

			padding-right: 80px;

			&-icon {
				position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
				right: 0;
				top: 50%;
				width: 30px;
				height: 30px;
				border-radius: 50%;
				background: var(--t-accent-color);
				transform: translateY(-50%);
				border: 2px solid var(--t-accent-color);
				transition: background-color 150ms ease-in-out;
				svg {
					color: white;
					width: 12px;
					height: auto;
					position: absolute;
					transition: all 150ms ease-in-out;
				}

				&:hover {
					background-color: white;
					svg {
						color: var(--t-accent-color);
					}
				}
			}

			&:hover {
				cursor: pointer;
			}
		}
		&-content {
			overflow: hidden;
			padding-bottom: 10px; // Just a smidge so no 'highlight' blocks overflow
			line-height: 1.5;

			> *,
			.captioned-media {
				margin-top: var(--p-rhythm);
			}

			.btn {
				margin-top: calc(var(--p-rhythm-sm) / 2);

				&:first-of-type {
					margin-top: var(--p-rhythm-sm);
				}
			}

			transition: height 250ms ease-out;
			height: var(--accordion-height);

			&.is-closed {
				height: 0;
				padding-bottom: 0;
			}
		}
	}

	&--video {
		width: 100%;
		height: auto;
		margin-bottom: var(--p-rhythm-sm);
	}
}

@include mixins.from-md {
	.accordion {
		&--item {
			padding: var(--p-rhythm-sm) 0;
			&-trigger {
				&-icon {
					width: 50px;
					height: 50px;
					svg {
						width: 16px;
					}
				}
			}
			&-content {
				padding-right: 28%;
				> *,
				.captioned-media {
					margin-top: var(--p-rhythm-sm);
				}
			}
			&-category {
				margin-right: 1.2em;
			}
		}

		&--video {
			margin-bottom: 0;
		}
	}
}
