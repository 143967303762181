@use "mixins";

.social-bar {
	display: flex;
	gap: 10px;

	&__link {
		display: flex;
		align-items: center;
		&-asset {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			background: white;
			svg {
				color: var(--t-accent-color);
				display: block;
				width: 18px;
				height: auto;
			}
		}
	}
}

@include mixins.from-md {
	.social-bar {
		gap: 25px;
		&__link {
			flex-direction: row-reverse;
			&-asset {
				width: 50px;
				height: 50px;
				svg {
					width: 22px;
				}
			}
		}
	}
}
