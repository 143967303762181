@use "components/buttons";
@use "mixins";

product-details {
	&::part(container) {
		display: flex;
		flex-direction: column-reverse;
	}
	&::part(title) {
		font-size: var(--fs-page-title);
		font-weight: 600;
		color: var(--t-accent-color);
		margin: 0;
	}

	&::part(price) {
		font-size: var(--fs-cta-text-title);
		font-weight: 600;
	}

	&::part(intro) {
		font-weight: 600;
		font-size: var(--fs-prose-title);
		line-height: 1.3;
	}
	&::part(description) {
		& ul {
			padding: 0;
		}
	}

	&::part(controls) {
		margin-top: var(--p-rhythm-sm);
		display: flex;
	}

	&::part(buy-button) {
		@include buttons.content-button;
		margin-top: 40px;
		font-size: var(--fs-ui-body);
		border-radius: 1.2em;
		font-weight: 600;
		min-width: 300px;
	}

	&::part(img) {
		width: 100%;
		height: auto;
		border-radius: var(--component-border-rad);
	}

	&::part(label) {
		font-weight: 600;
	}

	&::part(field) {
		position: relative;
		display: grid;
		row-gap: 10px;
		grid-template-rows: auto auto;
		margin-right: 15px;
	}

	&::part(select),
	&::part(quantity) {
		color: var(--t-text-color);
		outline: none;
		height: 38px;
		padding-left: 8px;
		border-radius: 6px;
		line-height: 1;
		box-sizing: border-box;
	}

	&::part(select) {
		appearance: none;
		border: var(--t-table-border);
		background: none;
		padding-right: 26px;
		min-width: 120px;
		position: relative;
		box-sizing: border-box;
	}

	&::part(quantity) {
		border: var(--t-table-border);
		outline: none;
		background: none;
		width: 50px;
	}

	&::part(select-arrow) {
		position: absolute;
		right: 10px;
		bottom: 13px;
		width: 16px;
	}

	@include mixins.to-md {
		&::part(controls) {
			margin-top: 40px;
		}
		&::part(img) {
			margin: var(--p-rhythm) 0;
		}
	}

	@include mixins.from-md {
		&::part(container) {
			display: grid;
			column-gap: var(--p-rhythm);
			grid-template-columns: 1fr 1fr;
		}
	}
}
