@use "constants";
@use "mixins";

.card {
	display: block;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-radius: var(--component-border-rad);
	overflow: hidden;
	background: white;

	&__image {
		width: 100%;
	}

	&__details {
		width: 100%;
		padding: 20px;
	}

	&__title {
		color: var(--t-accent-color);
		font-size: var(--fs-ui-title);
		margin-bottom: 1em;
		margin-top: constants.$font-y-offset;
	}

	&__summary {
		font-size: var(--fs-ui-body);
		line-height: 1.25;
		color: var(--t-text-color-light);
	}

	&:hover {
	}
}

.card-group {
	.card + .card {
		margin-top: var(--p-rhythm-lg);
	}
}

@include mixins.from-sm {
	.card {
		&__details {
			padding: 40px 25px;
		}
	}

	.card-group {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: var(--p-rhythm-sm);
		.card + .card {
			margin-top: 0;
		}
	}
}
