// All mixins too small for their own file go in here

@mixin font-smoothing() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/**
	Usage:
		.parent {
			@include aspect-ratio(16, 9);
			.child {
				position: absolute
			}
		}
*/
@mixin aspect-ratio($width, $height) {
	position: relative;

	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}
}
