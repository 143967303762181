@use "constants";
@use "mixins";

.resource {
	padding: calc(var(--p-rhythm) * 1.5) 0;
	margin: 0;
	list-style: none;
	border-bottom: 2px solid var(--t-accent-color);
	&:first-child {
		border-top: 2px solid var(--t-accent-color);
	}

	&__thumbnail {
		display: block;

		& > img {
			border-radius: var(--component-border-rad);
		}
	}

	&__title {
		margin: 1em 0;
		font-size: var(--fs-prose-title);
	}

	.btn {
		margin-top: 1em;
	}
}

.resource-group {
	list-style: none;
}

@include mixins.from-md {
	.resource {
		display: flex;
		flex-direction: row-reverse;
		gap: var(--p-rhythm);
		padding: var(--p-rhythm-sm) 0;

		&__copy {
			flex: 1;
		}

		&__title {
			margin-top: constants.$font-y-offset;
			margin-bottom: 0.45em;
		}

		&__thumbnail {
			min-width: 200px;
		}
		.btn {
			flex: 1;
			flex-basis: 100%;
			order: 100;
			margin-top: var(--p-rhythm-sm);
			width: min(24vw, 100%);
		}
	}
}
