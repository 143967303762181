@use "constants";
@use "mixins";
@use "global/bases";

.l-site-header {
	$c: &;

	position: relative;
	z-index: 1;

	max-width: var(--page-width);
	margin: 0 auto;
	margin-top: var(--page-gutter);

	padding: var(--p-rhythm);
	color: white;
	display: flex;
	justify-content: space-between;

	height: var(--header-height);

	&__logo {
		position: relative;
		z-index: 1;
		display: block;
		height: 41px;
		margin-top: -6px;
		img {
			width: auto;
			height: 100%;
		}
	}

	&__menu-trigger {
		$mt: &;
		@include bases.base-btn;
		height: 30px;
		position: relative;
		z-index: 3;

		&-close,
		&-open {
			font-size: 0;
			letter-spacing: 0;
			white-space: nowrap;
			span {
				font-size: var(--fs-prose-body);
				display: inline;

				transition: opacity 300ms ease-in-out;
				transform: translateX(10px);
				@for $i from 1 through 5 {
					&:nth-child(#{$i}) {
						transition-delay: ($i * 100ms);
					}
				}
			}
		}

		&-close {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			span {
				opacity: 0;
			}
		}

		&.is-active {
			#{$mt}-open {
				span {
					opacity: 0;
				}
			}
			#{$mt}-close {
				span {
					opacity: 1;
				}
			}
		}
	}

	.l-navigation {
		z-index: 2;
	}

	&:after {
		@include bases.base-bg-gfx;
		transition: height 200ms ease-in-out;
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: var(--block-border-rad);
		.is-home & {
			height: var(--header-overflow-gfx-height);
		}
	}
}

@include mixins.to-lg {
	.l-site-header {
		.l-navigation {
			transition: opacity 300ms ease-in-out;
			height: 100vh;
			width: 100%;
			padding: calc(var(--page-gutter) * 2);
			padding-top: calc(var(--page-gutter) * 3 + 80px);
			position: fixed;
			top: 0;
			left: 0;
			display: flex;
			flex-direction: column;
			overflow-y: scroll;

			opacity: 0;
			pointer-events: none;

			&.is-active {
				transition-delay: 500ms;
				opacity: 1;
				pointer-events: all;
			}
			&__item {
				position: relative;
				&-link {
					font-size: var(--fs-menu-text);
					padding: 0;
					background: none;
					color: white;
					font-weight: 600;
				}
			}

			&__item + &__item {
				margin-top: var(--p-rhythm);
			}

			&__actions {
				margin-top: var(--p-rhythm);
				&-link {
					margin-bottom: var(--p-rhythm);
				}
			}

			.orientation-changing & {
				transition: none;
			}
		}
	}

	.l-page,
	.l-site-footer {
		transition: opacity 200ms ease-in-out;
		transition-delay: 500ms;
	}

	.menu-open {
		.l-site-header {
			&:after {
				height: 100vh;
			}
		}

		.l-page,
		.l-site-footer {
			transition-delay: 0ms;
			opacity: 0;
			pointer-events: none;
		}
	}
}

@include mixins.from-md {
	.l-site-header {
		&__logo {
			height: 60px;
			margin-top: -30px;
		}
	}
}

@include mixins.from-lg {
	.l-site-header {
		display: flex;
		justify-content: space-between;
		margin-top: calc(var(--page-gutter) / 2);

		&:after {
			transition: none;
		}

		&__logo {
			width: 153px;
			height: auto;
			margin-top: -34px;
			img {
				width: 100%;
				height: auto;
			}
		}

		&__menu-trigger {
			display: none;
		}
	}
}
