@use "constants";
@use "mixins";

.l-home-about {
	padding: 0 var(--p-rhythm);
	padding-top: var(--p-rhythm-lg);
	padding-bottom: calc(var(--p-rhythm-lg) * 2);
	&__lede {
		line-height: 1.25;
		font-size: var(--fs-cta-text-title);
		margin-top: constants.$font-y-offset;
		color: var(--t-text-color-light);
	}
	.btn {
		margin-top: var(--p-rhythm);
	}
}

@include mixins.from-md {
	.l-home-about {
		padding-bottom: var(--p-rhythm-lg);
	}
}
