/*
* Hide only visually, but have it available for screen readers: h5bp.com/v
*/

.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0 !important;
	position: absolute !important;
	width: 1px;

	&.focusable:active,
	&.focusable:focus {
		clip: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		position: static;
		width: auto;
	}
}
