@use "constants";
@use "mixins";
@use "global/bases";

.content-cta {
	border-radius: var(--component-border-rad);
	overflow: hidden;
	background: white;
	&__media {
		&--2x-media {
			display: grid;
			grid-template-columns: 1fr 1fr;
			picture {
				img {
					height: 100%;
				}
			}
		}
	}
	&__copy {
		padding: var(--p-rhythm);
		&-title {
			color: var(--t-accent-color);
			font-size: var(--fs-ui-title);
			margin-bottom: 1em;
			margin-top: constants.$font-y-offset;
		}
		&-summary {
			color: var(--t-text-color-light);
			font-size: var(--fs-ui-body-lg);
			line-height: 1.35;
		}
		.btn {
			margin-top: var(--p-rhythm-lg);
			display: inline-flex;
		}
	}
}

@include mixins.from-md {
	.content-cta {
		$c: &;
		&__copy {
			position: relative;
			display: grid;
			grid-template-columns: 1fr 1fr;
			padding: calc(1.2 * var(--p-rhythm-sm)) var(--p-rhythm-sm);
			&-title {
				padding-right: 235px;
				margin-bottom: 0;
			}
			.btn {
				font-size: var(--fs-ui-title);
				position: absolute;
				right: calc(50% + (var(--p-rhythm-sm) / 2));
				margin-top: 0;
				top: calc(1.5 * var(--p-rhythm-sm));
			}
		}

		&--no-summary {
			#{$c}__copy {
				&-title {
					grid-column: 1 / 3;
				}
				.btn {
					top: 50%;
					transform: translateY(-50%);
					right: var(--p-rhythm);
				}
			}
		}
	}
}

@include mixins.from(1700px) {
	.content-cta {
		&__copy {
			.btn {
				right: calc(50% + 100px);
			}
		}
	}
}
