@use "mixins";
@use "global/bases";

@mixin content-button() {
	transition: all 250ms ease-in-out;
	border-radius: 20px;
	padding: 9px 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: auto;

	background: var(--t-accent-color);
	border: 2px solid var(--t-accent-color);
	color: white;

	&:hover,
	&:focus,
	&:active {
		color: var(--t-accent-color);
		background: white;
		cursor: pointer;
	}
}

.btn {
	@include content-button();

	svg {
		width: 25px;
		height: 13px;
	}

	&--minimal {
		padding: 15px 30px;
	}

	&--short {
		padding-top: 4px;
		padding-bottom: 4px;
		border-radius: 15px;
	}

	&--inverse {
		& > svg {
			transform: rotateY(180deg);
		}
	}

	&--light {
		background: white;
		border-color: white;
		color: var(--t-accent-color-dark);
		&:hover,
		&:focus,
		&:active {
			color: white;
			background: var(--t-accent-color);
		}
	}
}

.btn-group {
	.btn + .btn {
		margin-top: 20px;
	}
}

@include mixins.from-lg {
	.btn {
		border-radius: 25px;
		padding: 13px 30px;
		svg {
			width: 32px;
			height: 17px;
		}
		&--fixed {
			width: 24vw;
		}
		&--minimal {
			width: 100px;
			height: 50px;
		}
	}
}
