@font-face {
	font-family: "omnes-pro";
	src:
		url("../fonts/omnes-pro-normal-500.woff2") format("woff2"),
		url("../fonts/omnes-pro-normal-500.woff") format("woff"),
		url("../fonts/omnes-pro-normal-500.ttc") format("opentype");
	font-display: swap;
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: "omnes-pro";
	src:
		url("../fonts/omnes-pro-normal-600.woff2") format("woff2"),
		url("../fonts/omnes-pro-normal-600.woff") format("woff"),
		url("../fonts/omnes-pro-normal-600.ttc") format("opentype");
	font-display: swap;
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: "omnes-pro";
	src:
		url("../fonts/omnes-pro-italic-600.woff2") format("woff2"),
		url("../fonts/omnes-pro-italic-600.woff") format("woff"),
		url("../fonts/omnes-pro-italic-600.ttc") format("opentype");
	font-display: swap;
	font-style: italic;
	font-weight: 600;
}

@font-face {
	font-family: "omnes-pro";
	src:
		url("../fonts/omnes-pro-normal-700.woff2") format("woff2"),
		url("../fonts/omnes-pro-normal-700.woff") format("woff"),
		url("../fonts/omnes-pro-normal-700.ttc") format("opentype");
	font-display: swap;
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: "anonymous-pro";
	src:
		url("../fonts/anonymous-pro-normal-400.woff2") format("woff2"),
		url("../fonts/anonymous-pro-normal-400.woff") format("woff"),
		url("../fonts/anonymous-pro-normal-400.ttc") format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 400;
}
