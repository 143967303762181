@use "mixins";

.products-listing {
	display: grid;
	grid-template-columns: 1fr;
	gap: 60px;
	width: 100%;
	margin-bottom: var(--p-rhythm-lg);

	&__title {
		font-size: var(--fs-page-title);
		color: var(--t-accent-color);
		margin-bottom: 1em;
	}

	@include mixins.from-md {
		grid-template-columns: 1fr 1fr;
		gap: var(--p-rhythm) var(--p-rhythm-lg);
	}
}

product-listing-item {
	&::part(container) {
		display: block;
		width: 100%;
		text-decoration: none;
		text-decoration: none;
		color: var(--t-text-color);
		border-bottom: 2px solid var(--t-accent-color);
		height: 100%;
	}

	&::part(image) {
		width: 100%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		border-radius: var(--component-border-rad);

		&:after {
			content: "";
			display: block;
			padding-bottom: 100%;
		}
	}

	&::part(title) {
		font-size: var(--fs-prose-title);
		font-weight: 400;
		margin: 1em 0 0 0;
	}

	&::part(price) {
		font-weight: bold;
		font-size: var(--fs-prose-title);
	}
}
