@use "constants";
@use "mixins";

@function strip-units($number) {
	// Strips units from string e.g. 15px -> 15
	@return $number / ($number * 0 + 1);
}

@mixin gen-clamp(
	$propName,
	$minFontSizePx,
	$maxFontSizePx,
	$minWidthPx: constants.$bp-tiny,
	$maxWidthPx: constants.$bp-very-large
) {
	$minWidth: strip-units($minWidthPx) / 16;
	$maxWidth: strip-units($maxWidthPx) / 16;
	$minFontSize: strip-units($minFontSizePx) / 16;
	$maxFontSize: strip-units($maxFontSizePx) / 16;

	$slope: ($maxFontSize - $minFontSize) / ($maxWidth - $minWidth);
	$yAxisIntersection: -$minWidth * $slope + $minFontSize;

	#{$propName}: clamp(
		#{$minFontSize * 1rem},
		#{($yAxisIntersection * 1rem)} + " + " + #{($slope * 100vw)},
		#{$maxFontSize * 1rem}
	);

	// If these varaible names change, update them in updateClampedCSS.js
	@at-root {
		:export {
			#{$propName}_min-width: #{strip-units($minWidthPx)};
			#{$propName}_max-width: #{strip-units($maxWidthPx)};
			#{$propName}_min-fs: #{strip-units($minFontSizePx)};
			#{$propName}_max-fs: #{strip-units($maxFontSizePx)};
		}
	}
}

:root {
	--t-text-color: #4f5866;
	--t-text-color-light: #6e7a8d;

	--t-accent-color: #0070f2;
	--t-accent-color-dark: #2d72ea;
	--t-page-bg-color: #ffffff;

	--t-table-border: 1px solid #d2d2d2;
	--t-table-header-bg: #f5f1f1;

	--t-green-light: #d9ffc9;
	--t-green-dark: #37a349;

	--p-rhythm: 20px;
	--p-rhythm-sm: calc(var(--p-rhythm) / 2);
	--p-rhythm-lg: 50px;

	--page-gutter: 20px;
	--page-width: min(calc(100vw - var(--page-gutter) * 2), 1790px);

	--block-border-rad: 15px;
	--component-border-rad: 15px;

	--header-height: 69px;
	--header-overflow-gfx-height: 70vh;

	--body-font: #{constants.$body-font};
	--bp-large: #{constants.$bp-large};

	@include gen-clamp("--fs-page-title", 40px, 90px);
	@include gen-clamp("--fs-home-fold-title", 40px, 100px);
	@include gen-clamp("--fs-menu-text", 40px, 24px);
	@include gen-clamp("--fs-mini-menu-text", 20px, 18px);
	@include gen-clamp("--fs-backlink-text", 16px, 18px);
	@include gen-clamp("--fs-social-text", 25px, 24px);
	@include gen-clamp("--fs-cta-text-title", 30px, 60px);
	@include gen-clamp("--fs-btn-text", 18px, 24px);
	@include gen-clamp("--fs-accordion-supertitle", 16px, 22px);

	@include gen-clamp("--fs-ui-title", 25px, 50px);
	@include gen-clamp("--fs-ui-body-lg", 20px, 30px);
	@include gen-clamp("--fs-ui-body", 20px, 24px);
	@include gen-clamp("--fs-prose-title", 25px, 40px);
	@include gen-clamp("--fs-prose-body", 18px, 22px);
}

@include mixins.from-md {
	:root {
		--p-rhythm: 50px;
		--p-rhythm-sm: calc(var(--p-rhythm) / 2);
		--p-rhythm-lg: calc(var(--p-rhythm) * 1.5);
	}
}

@include mixins.from-lg {
	:root {
		--p-rhythm: 100px;

		--page-gutter: 100px;

		--block-border-rad: 60px;
		--component-border-rad: 30px;

		--header-height: 261px;
		--header-overflow-gfx-height: 130vh;

		--text-column-width: 600px;
		--page-column-width: 700px;
	}
}

@include mixins.from(1440px) {
	:root {
		--text-column-width: 600px;
		--page-column-width: 830px;
	}
}

@include mixins.from(1600px) {
	:root {
		--page-gutter: 150px;

		--text-column-width: 667px;
		--page-column-width: 930px;
	}
}

// //MOBILE
// // 40px - Home fold heading, Page heading, menu text
// // 30px - Home lede text, Footer contact us heading
// // 25px - Card title, accordion title, Social link text, subheading, blockquote text, body lede
// // 20px - Card summary, cta block summary, menu sign in text
// // 18px - Btn text, body text
// // 16px - Backlink text, accordion supertitle

// // DESKTOP
// // 100px - Home fold heading
// // 90px - Page heading
// // 60px - Home lede text, Footer contact us heading
// // 50px - Card title, accordion title
// // 40px - subheading, blockquote text, body lede
// // 30px - cta block summary
// // 24px - Menu text, Btn text, Card summary, Social link text
// // 22px - Body text, accordion supertitle
// // 18px - menu sign in text

// --page-title: 40px, 90px;
// --home-fold-title: 40px, 90px;
// --menu-text: 40px, 24px;
// --mini-menu-text: 20px, 18px;
// --backlink-text: 16px, 18px;
// --social-text: 25px, 24px;
// --cta-text-title: 30px, 60px;
// --btn-text: 18px, 24px;
// --accordion-supertitle: 16px, 22px;

// --ui-title: 25px, 50px;
// --ui-body: 20px, 54px;
// --prose-title: 25px, 40px;
// --prose-body: 18px, 22px;
