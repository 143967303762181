@use "mixins";
@use "global/bases";

.l-page {
	max-width: var(--page-width);
	margin: 0 auto;
	position: relative;
	z-index: 2; // So that it appears above the header overflowing background

	&__gfx-padder {
		padding: var(--p-rhythm-lg) var(--p-rhythm);
	}
	&__padder {
		padding: var(--p-rhythm-lg) 0;
	}

	&__gfx-padder {
		@include bases.base-bg-gfx;
		border-radius: var(--block-border-rad);
	}

	&__section + &__section {
		margin-top: calc(var(--p-rhythm) * 2);
	}

	&__bottom-nav {
		max-width: var(--page-column-width);
		margin-top: var(--p-rhythm-lg);
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-areas: "prev next";
		column-gap: var(--p-rhythm-lg);

		&-next {
			grid-area: next;
		}

		&-prev {
			grid-area: prev;
		}
	}

	.backlink {
		margin-bottom: var(--p-rhythm-lg);
	}

	&__print-button {
		position: absolute;
		top: var(--p-rhythm-lg);
		right: 0;
	}

	&__title {
		font-size: var(--fs-page-title);
		color: var(--t-accent-color);
		margin-bottom: 1em;
	}
}

@include mixins.to-sm {
	.l-page {
		&__print-button {
			display: none;
		}
	}
}

@include mixins.to(450px) {
	.l-page {
		&__bottom-nav {
			grid-template-rows: 1fr 1fr;
			grid-template-areas:
				"prev"
				"next";
			grid-gap: var(--p-rhythm);
			grid-template-columns: none;
		}
	}
}

@include mixins.from-md {
	.l-page {
		&__padder,
		&__gfx-padder {
			padding: var(--p-rhythm);
		}

		&__print-button {
			right: var(--p-rhythm);
			top: var(--p-rhythm);
		}

		.backlink {
			margin-bottom: var(--p-rhythm);
		}
	}
}
