@use "mixins";
@use "global/bases";

.l-curriculum-block {
	&__title {
		@include bases.base-heading;
		font-size: var(--fs-prose-title);
	}
	&__module-chooser {
		position: relative;
		display: grid;
		padding: 0;
		&:before {
			content: "";
			background-image: url("../images/arrow-down.svg");
			background-repeat: no-repeat;
			width: 19px;
			height: 11px;
			position: absolute;
			top: 50%;
			margin-top: -5px;
			right: 23px;
			pointer-events: none;
		}
		&-control {
			border: 0 none;
			border-radius: 20px;
			padding: 9px 15px;

			justify-content: space-between;
			align-items: center;
			width: auto;

			background: var(--t-accent-color-dark);
			color: white;
			appearance: none;

			&:hover,
			&:focus,
			&:active {
				cursor: pointer;
			}
		}
	}
	&__subtitle {
		@include bases.base-heading;
		font-size: var(--fs-ui-body);
		color: var(--t-text-color-light);
		margin-top: 1.5em;
		margin-bottom: 0.8em;
	}
	&__table {
		border: var(--t-table-border);
		width: 100%;
		border-collapse: collapse;
		tr {
			border-bottom: var(--t-table-border);
			&:last-child {
				border-bottom: none;
			}
		}
		td,
		th {
			vertical-align: top;
			border-right: var(--t-table-border);
			&:last-child {
				border-right: none;
			}
			&[data-module] {
				width: 50px;
			}
			&[data-module]:not(.is-visible) {
				display: none;
			}
		}
		th {
			font-size: 16px;
			background: var(--t-table-header-bg);
			padding: 9px;
			text-align: center;
			text-transform: uppercase;
			font-weight: normal;
			&:first-child {
				text-align: left;
			}
		}
		td {
			padding: 9px;
		}
		&-cell-title {
			color: var(--t-text-color-light);
		}

		&-cell-data {
			padding: 0 !important;
			height: 1px; // Hack to make child elements full height
		}
	}

	&__cell-tick {
		height: 100%;
		background: var(--t-green-light);
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 100px;
		svg {
			width: 21px;
			height: 15px;
		}
	}
	&__cell-empty {
		height: 100%;
		min-height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 100px;
	}
}

@include mixins.to-md {
	.l-curriculum-block {
		&__module-chooser {
			margin-top: var(--p-rhythm-sm);
		}

		&__scroller {
			--grad-size: 15px;
			width: calc(100% + (2 * var(--grad-size)));
			margin-left: calc(-1 * var(--grad-size));
			position: relative;
			&:before,
			&:after {
				content: "";
				position: absolute;
				width: 15px;
				height: 100%;
				top: 0;
				right: 0;
				background: linear-gradient(to right, transparent, white);
			}
			&:before {
				right: auto;
				left: 0;
				background: linear-gradient(to left, transparent, white);
			}
			&-inner {
				max-width: 100%;
				overflow: auto;
			}
			&-inner-track {
				display: grid;
				grid-template-columns: 15px 1fr 15px;
				&:before,
				&:after {
					content: "";
					width: 15px;
					height: 100%;
				}
				table {
				}
			}
		}
	}
}
@include mixins.from-md {
	.l-curriculum-block {
		width: 100%;
		display: grid;
		grid-template-areas:
			"title chooser"
			"tables tables";
		grid-template-columns: 1fr 250px;
		&__title {
			grid-area: title;
		}
		&__module-chooser {
			grid-area: chooser;
			transform: translateY(calc(100% + (0.333 * var(--fs-ui-body))));
			&-control {
				border-radius: 25px;
				padding: 13px 30px 13px 23px;
			}
		}
		&__table-container {
			grid-area: tables;
		}
		&__table {
			td {
				padding: 18px 9px;
			}
		}
	}
}
