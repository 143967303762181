@use "constants";
@use "mixins";

.article {
	padding: calc(var(--p-rhythm) * 1.5) 0;
	margin: 0;
	list-style: none;
	border-bottom: 2px solid var(--t-accent-color);

	&:first-child {
		border-top: 2px solid var(--t-accent-color);
	}

	&__thumbnail {
		display: block;
		margin-bottom: 1.375em;

		& > img {
			border-radius: var(--component-border-rad);
		}
	}

	&__date {
		color: var(--t-text-color-light);
		margin-bottom: 0.5em;
	}

	&__title {
		margin: 0.5em 0;
		font-size: var(--fs-prose-title);
	}

	&__description {
		color: var(--t-text-color-light);
	}
}

.article-group + .pagination {
	margin-top: calc(var(--p-rhythm) * 1.5);
}

@include mixins.from-md {
	.article {
		display: flex;
		flex-direction: row-reverse;
		gap: var(--p-rhythm);
		padding: var(--p-rhythm-sm) 0;

		&__copy {
			flex: 1;
		}

		&__date {
			margin-bottom: 1em;
		}

		&__title {
			margin-top: constants.$font-y-offset;
			margin-bottom: 0.45em;
		}

		&__thumbnail {
			min-width: 200px;
			margin: 0;
		}
	}

	.article-group + .pagination {
		margin-top: 70px;
	}
}
